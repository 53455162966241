export const buttonVariants = Object.freeze({
  base: 'text-base focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 max-h-[48px]',
  font: {
    custom: '',
    boldWhite: 'text-white font-bold',
    boldOrange: 'text-orange-600 font-bold',
    boldMap: 'text-primary hover:text-primary-text font-bold',
    boldGray: 'text-gray-800 font-bold',
    boldLowContrast: 'text-gray-200 font-bold',
    boldGrayBlue: 'text-tacticGrayBlue-700 font-bold',
    normalWhite: 'text-white font-normal',
    normalGray: 'text-gray-800 font-normal',
    boldPrimary: 'text-primary-text font-bold',
    boldBrand: 'text-brand font-bold',
    boldPrimaryTransparent:
      'text-primary font-bold hover:text-primary-text active:text-primary'
  },
  size: {
    xl: 'px-10 py-4',
    lg: 'px-8 py-3',
    base: 'px-6 py-2 md:px-4',
    sm: 'px-3 py-1 text-sm',
    custom: '',
    icon: {
      xl: 'p-4',
      lg: 'p-3',
      base: 'p-2',
      sm: 'p-2',
      custom: '',
      pagination: ''
    },
    pagination: 'px-3 py-1 rounded-md text-xs'
  },
  variants: {
    orange: {
      base: 'bg-primary focus-visible:ring-primary',
      active:
        'active:bg-primary md:hover:bg-primary-hover focus:shadow-bg-primary',
      disabled: 'bg-disabled cursor-not-allowed'
    },
    blue: {
      base: 'bg-secondary-bg hover:bg-secondary-hover focus:ring-blue-800',
      active:
        'active:bg-secondary hover:bg-secondary focus:shadow-outline-primary',
      disabled: 'opacity-50 cursor-not-allowed'
    },
    white: {
      base: 'bg-white hover:bg-gray-50 border border-gray-300 focus:outline-none border border-gray-300',
      active:
        'active:bg-transparent hover:bg-gray-100 focus:shadow-outline-gray',
      disabled: 'opacity-50 cursor-not-allowed'
    },
    transparent: {
      base: 'bg-transparent hover:bg-gray-50 border border-gray-300 focus:outline-none',
      active:
        'active:bg-transparent hover:bg-gray-100 focus:shadow-outline-gray',
      disabled: 'opacity-50 cursor-not-allowed'
    },
    transparentOrange: {
      base: 'bg-transparent hover:bg-primary-hover border border-primary focus:outline-none',
      active:
        'active:bg-transparent hover:bg-primary-hover focus:shadow-outline-primary',
      disabled: 'opacity-50 cursor-not-allowed'
    },
    bordlessTransparent: {
      base: 'bg-transparent hover:bg-gray-100 border border-transparent focus:outline-none',
      active:
        'active:bg-transparent hover:bg-gray-100 focus:shadow-outline-gray',
      disabled: 'opacity-50 cursor-not-allowed'
    },
    primary: {
      base: 'bg-primary hover:bg-primary-hover',
      active:
        'active:bg-primary md:hover:bg-primary-hover focus:shadow-bg-primary',
      disabled: 'bg-disabled cursor-not-allowed'
    },
    transparentPrimary: {
      base: 'bg-transparent hover:bg-primary border border-primary focus:outline-none',
      active:
        'active:bg-transparent hover:bg-primary focus:shadow-outline-primary',
      disabled: 'bg-disabled opacity-50 cursor-not-allowed'
    },
    transparentInvisible: {
      base: 'bg-transparent focus:outline-none hover:bg-gray-100 !shadow-none',
      active: 'active:bg-transparent focus:shadow-outline-primary',
      disabled: 'bg-disabled opacity-50 cursor-not-allowed'
    },
    custom: {
      base: '',
      active: '',
      disabled: ''
    },
    invisible: {
      base: 'bg-transparent focus:outline-none hover:bg-transparent !shadow-none',
      active: 'active:bg-transparent focus:bg-transparent',
      disabled: 'bg-transparent opacity-50 cursor-not-allowed'
    }
  },
  severity: {
    info: {
      base: 'bg-blue-100 text-blue-800',
      active: 'active:bg-blue-200 hover:bg-blue-200 focus:shadow-outline-blue',
      disabled: 'opacity-50 cursor-not-allowed'
    },
    critical: {
      base: 'bg-red-100 text-red-700',
      active:
        'active:bg-red-200 active:text-red-900 hover:bg-red-200 hover:text-red-900 focus:shadow-outline-red',
      disabled: 'opacity-50 cursor-not-allowed'
    },
    warning: {
      base: 'bg-yellow-100 text-yellow-800',
      active:
        'active:bg-yellow-200 hover:bg-yellow-200 focus:shadow-outline-yellow',
      disabled: 'opacity-50 cursor-not-allowed'
    },
    success: {
      base: 'bg-green-100 text-green-800',
      active:
        'active:bg-green-200 hover:bg-green-200 focus:shadow-outline-green',
      disabled: 'opacity-50 cursor-not-allowed'
    }
  },
  // styles applied to the SVG icon
  icon: {
    xl: 'h-5 w-5',
    lg: 'h-5 w-5',
    base: 'h-5 w-5',
    sm: 'h-3 w-3',
    md: 'h-4 w-4',
    left: 'mr-2 -ml-1',
    right: 'ml-2 -mr-1',
    custom: '',
    pagination: ''
  },
  rounded: 'rounded-full',
  squared: 'rounded-md',
  normal: 'rounded',
  block: 'inline-block w-full rounded-md text-center',
  link: {
    base: 'text-gray-600 focus:outline-none border border-transparent',
    active: '',
    disabled: 'opacity-50 cursor-not-allowed'
  },
  // this is the button that lives inside the DropdownItem
  dropdownItem: {
    base: 'inline-flex items-center cursor-pointer w-full px-2 py-1 text-sm font-medium transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800'
  },
  justifyCenter: 'justify-center'
});
